<template>
  <view-item-setting>
    <template #operation>
      <en-button type="primary" @click="operation.create.click">生成序列号</en-button>
      <en-button type="primary" @click="operation.save.click">保存</en-button>
    </template>

    <div class="flex items-center gap-6 text-sm">
      <span>是否开启序列号管理</span>
      <en-switch
        v-model="enabled.data"
        active-value="Y"
        inactive-value="N"
        active-text="开"
        inactive-text="关"
        @change="enabled.radio.change"
      ></en-switch>
      <span class="flex items-center">
        <span class="text-red-500">{{ table.count }}</span>
        个设备授权
      </span>
    </div>

    <flex-box v-if="enabled.data === 'Y'">
      <template #default="{ height }">
        <en-table :data="table.data" :height="height" :loading="table.loading">
          <en-table-column label="序列码" prop="serialNo"></en-table-column>
          <en-table-column label="起始时间">
            <template #default="{ row }: { row: EnocloudCommonDefinitions['LicenceDto'] }">
              <span>{{ formatDate(row.preparedDatetime) }}</span>
            </template>
          </en-table-column>
          <en-table-column label="截止时间">
            <template #default="{ row }: { row: EnocloudCommonDefinitions['LicenceDto'] }">
              <span>{{ formatDate(row.dueDate) }}</span>
            </template>
          </en-table-column>
          <en-table-column label="状态" prop="status.message"></en-table-column>
          <en-table-column label="成员">
            <template #default="{ row }: { row: EnocloudCommonDefinitions['LicenceDto'] }">
              <select-maintain
                v-model="row.owner"
                :ajax="{
                  action: 'GET /enocloud/admin/user',
                  params: (params, value) => (params.payload = { name: value, status: 'A' })
                }"
                :props="{ label: 'name', value: '' }"
                value-key="id"
                remote
              ></select-maintain>
            </template>
          </en-table-column>
          <en-table-column label="备注">
            <template #default="{ row }: { row: EnocloudCommonDefinitions['LicenceDto'] }">
              <en-input v-model="row.comment"></en-input>
            </template>
          </en-table-column>
          <en-table-column label="操作">
            <template #default="{ row }: { row: EnocloudCommonDefinitions['LicenceDto'] }">
              <en-button v-if="row.status?.code === 'A'" link type="primary" @click="table.operation.close.click(row)">停用</en-button>
              <en-button v-if="row.status?.code === 'D'" link type="primary" @click="table.operation.open.click(row)">启用</en-button>
              <en-tooltip v-if="row.status?.code !== 'A'" content="删除后，该机器上序列号失效 !" effect="dark" placement="top">
                <en-button link type="primary" @click="table.operation.delete.click(row)">删除</en-button>
              </en-tooltip>
              <en-tooltip v-else content="重置后，该机器需要再次使用该序列号授权登录 !" effect="dark" placement="top">
                <en-button link type="primary" @click="table.operation.reset.click(row)">重置</en-button>
              </en-tooltip>
            </template>
          </en-table-column>
        </en-table>
      </template>
    </flex-box>
  </view-item-setting>
</template>

<script lang="ts">
import { filter } from 'lodash-es'

export default factory({
  config: {
    children: {
      operation: {
        create: {
          async click() {
            await this.table.submit()
            this.table.get()
          }
        },
        save: {
          async click() {
            await this.table.submit({})
            this.table.get()
          }
        }
      },
      enabled: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/licence/enabled',
            data: 'string'
          },
          update: {
            action: 'PUT /enocloud/common/licence/enabled',
            params(params) {
              params.payload = this.enabled.data
            }
          }
        },
        children: {
          radio: {
            change() {
              this.enabled.update()
            }
          }
        }
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/licence',
            data: 'array',
            loading: true
          },
          submit: {
            action: 'PUT /enocloud/common/licence',
            params(params) {
              params.data = this.table.data
            }
          },
          update: {
            action: 'PUT /enocloud/common/licence'
          },
          close: {
            action: 'POST /enocloud/common/licence/:licenceId/close'
          },
          reverse: {
            action: 'POST /enocloud/common/licence/:licenceId/reverse'
          },
          delete: {
            action: 'DELETE /enocloud/common/licence/:licenceId'
          }
        },
        computed: {
          count() {
            return filter(this.table.data, ['status.code', 'A']).length
          }
        },
        children: {
          operation: {
            open: {
              async click(row: EnocloudCommonDefinitions['LicenceDto']) {
                await this.table.reverse({ paths: [row.id] })
                this.table.get()
              }
            },
            close: {
              async click(row: EnocloudCommonDefinitions['LicenceDto']) {
                await this.table.close({ paths: [row.id] })
                this.table.get()
              }
            },
            reset: {
              async click(row: EnocloudCommonDefinitions['LicenceDto']) {
                await this.table.reverse({ paths: [row.id] })
                this.table.get()
              }
            },
            delete: {
              async click(row: EnocloudCommonDefinitions['LicenceDto']) {
                await this.table.delete({ paths: [row.id] })
                this.table.get()
              }
            }
          }
        }
      }
    }
  },
  mounted() {
    this.table.get()
    this.enabled.get()
  }
})
</script>
